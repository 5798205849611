body,
html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: hidden
}

#container,
#container>div:first-child {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.lottie-disabled-transform svg {
  transform: unset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ht_selected {
  background: none !important;
}

[class$="ExpandedWidget__isEmbedded"] {
  width: 100% !important;
}

@keyframes fadeInScaleUp {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

textarea._hj-aMKgg__EmotionComment__textArea._hj-EZqbk__styles__inputField._hj-l2lLN__EmotionComment__isEmbedded {
  height: 120px !important;

}

._hj-widget-container dialog._hj-YfX8b__styles__modal {
  height: 100vh;
  height: 100dvh
}

.AnimationfadeInScaleUp .MuiPaper-root.MuiPaper-elevation {
  animation: fadeInScaleUp 400ms cubic-bezier(0, .94, .07, .99);
}

.ht_hyperTreeView {
  width: max-content;
  min-width: 100vw;
}

.ht_hyperTreeView .ht_hyperTreeNodeWrapper .ht_child:not(.ht_collapsed) {
  position: relative;
  animation: fadeInScaleUp 540ms cubic-bezier(0, .94, .07, .99) both;
}